/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-template */
/* eslint-disable quotes */
/* eslint-disable object-property-newline, no-dupe-keys */
/* eslint-disable no-nested-ternary */
/* eslint-disable operator-linebreak */
// DEPENDENCIES
import React, {
  useState,
  useEffect
} from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import Box from '@mui/material/Box';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import InputBlock from '../../../../../components/InputBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import SelectBlock from '../../../../../components/SelectBlock';
import Button from '../../../../../components/Button';

// HELPERS AND SERVICES
import * as wellPumpageService from '../../../../../services/wellPumpage/WellPumpageService';
import * as clientService from '../../../../../services/management/clientService';
import * as aquiferService from '../../../../../services/management/aquiferService';
import * as parishService from '../../../../../services/management/parishService';
import * as helper from '../../../../../helpers/helper';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
import * as authRedux from '../../../../../redux/authRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';
import Overlay from '../../../../../components/Overlay';

const model = {
  startDate: new Date().getFullYear(),
  endDate: new Date().getFullYear(),
  wellNumber: null,
  quarter: [helper.getCurrentQuarter()],
  clientId: [],
  parishId: [],
  aquiferId: []
};

const quarters = [1, 2, 3, 4];

const tabTypes = [
  { id: 1, name: 'Monthly (1000 gal)' },
  { id: 2, name: 'Hourly (1000 gal)' },
  // { id: 3, name: 'Daily (1000 gal)' },
];
const pageSize = 5;
const WellDataPage = (props) => {
  const { showAlert, auth } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [wellPumpages, setWellPumpages] = useState([]);
  const [activeTab, setActiveTab] = useState('0');
  const [startDate, setStartDate] = useState('');
  const [quarterOptions, setquarterOptions] = useState(null);
  const [clients, setClients] = useState([]);
  const [aquifers, setAquifers] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [filterModel, setFilterModel] = useState({ ...model });
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [wellDataTypes, setWellDataTypes] = useState(tabTypes);
  const [selectedClientOption, setSelectedClientOption] = useState([]);
  const [selectedAquiferOption, setSelectedAquiferOption] = useState([]);
  const [selectedParishOption, setSelectedParishOption] = useState([]);
  const [applyFilter, setApplyFilter] = useState(false);
  const [selectedStartDateOption, setSelectedStartDateOption] =
    useState({ label: new Date().getFullYear(), value: new Date().getFullYear() });
  const [selectedEndDateOption, setSelectedEndDateOption] =
    useState({ label: new Date().getFullYear(), value: new Date().getFullYear() });
  const [selectedquarterOption, setSelectedquarterOption] =
    useState([{ label: helper.getCurrentQuarter(), value: helper.getCurrentQuarter() }]);

  useEffect(() => {
    setquarterOptions(quarters.map((x) => helper.setToOptionModel(x, x)));
    fetchClients();
    fetchAquifers();
    fetchParishes();
  }, []);

  useEffect(() => {
    if (auth.role !== 'Admin') {
      setWellDataTypes(tabTypes.filter((x) => x.name !== 'Hourly (1000 gal)'));
    }
    if (parseInt(activeTab, 10) === 1) {
      getAllHistoricWellPumpages();
    }
    if (parseInt(activeTab, 10) === 2) {
      getHourlyPumpageData();
    }
    if (parseInt(activeTab, 10) === 3) {
      getDailyPumpageData();
    }
  }, [activeTab, applyFilter, pageNumber, pageSize]);

  const fetchClients = () => {
    clientService.getAllClients(true).then((res) => {
      const tempClients = res.map((x) => ({ label: x.name, value: x.id }));
      setClients(tempClients);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const fetchAquifers = () => {
    aquiferService.getAquifers().then((res) => {
      const tempAquifers = res.map((x) => ({ label: x.name, value: x.id }));
      setAquifers(tempAquifers);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const fetchParishes = () => {
    parishService.getParishes().then((res) => {
      const tempParishes = res.map((x) => ({ label: x.name, value: x.id }));
      setParishes(tempParishes);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const handleSelect = (eventKey) => {
    setActiveTab(eventKey);
    setTotalPages(0);
    setPageNumber(0);
  };

  const handleReset = () => {
    setFilterModel({
      clientId: [],
      startDate: new Date().getFullYear(),
      endDate: new Date().getFullYear(),
      wellNumber: null,
      endDate: null,
      quarter: [helper.getCurrentQuarter()],
      aquiferId: [],
      parishId: []
    });
    setSelectedClientOption([]);
    // setSelectedquarterOption(
    //   []
    // );
    setSelectedquarterOption(
      [{ label: helper.getCurrentQuarter(), value: helper.getCurrentQuarter() }]
    );
    setSelectedStartDateOption({
      label: new Date().getFullYear(),
      value: new Date().getFullYear()
    });
    setSelectedEndDateOption({
      label: new Date().getFullYear(),
      value: new Date().getFullYear()
    });
    setSelectedParishOption([]);
    setActiveTab('0');
    setSelectedAquiferOption([]);
    setStartDate(new Date());
    setApplyFilter(!applyFilter);
  };

  const getAllHistoricWellPumpages = () => {
    setIsLoading(true);
    wellPumpageService.getHistoricWellPumpages({
      ...filterModel,
      isCurrent: false,
      pageNumber,
      pageSize,
      getAll: false
    })
      .then((res) => {
        const pageData = res.data;
        setWellPumpages(pageData);
        setTotalPages(Math.ceil(res.totalRecords / pageSize));
      }).catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const getAllHistoricDataForExport = () => {
    setIsLoading(true);
    return wellPumpageService.getHistoricWellPumpages({
      ...filterModel,
      isCurrent: false,
      pageNumber,
      pageSize,
      getAll: true
    })
      .then((res) => res.data)
      .catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const getAllHourlyWellPumpagesToExport = () => {
    setIsLoading(true);
    return wellPumpageService.getHourlyWellPumpages({
      ...filterModel,
      pageNumber,
      pageSize,
      getAll: true
    })
      .then((res) => {
        setIsLoading(false);
        return res.data;
      }).catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
        setIsLoading(false);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const getHourlyPumpageData = () => {
    setIsLoading(true);
    wellPumpageService.getHourlyWellPumpages({
      ...filterModel,
      pageNumber,
      pageSize,
      getAll: false
    })
      .then((res) => {
        const pageData = res.data;
        setWellPumpages(pageData);
        setTotalPages(Math.ceil(res.totalRecords / pageSize));
      }).catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const getAllDailyWellPumpagesToExport = () => {
    setIsLoading(true);
    return wellPumpageService.getDailyWellPumpages({
      ...filterModel,
      pageNumber,
      pageSize,
      getAll: true
    })
      .then((res) => {
        setIsLoading(false);
        return res.data;
      }).catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
        setIsLoading(false);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const getDailyPumpageData = () => {
    setIsLoading(true);
    wellPumpageService.getDailyWellPumpages({
      ...filterModel,
      pageNumber,
      pageSize,
      getAll: false
    })
      .then((res) => {
        const pageData = res.data;
        setWellPumpages(pageData);
        setTotalPages(Math.ceil(res.totalRecords / pageSize));
      }).catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const handleExport = async (pumpageData, activeTab) => {
    let extractedData = pumpageData;
    if (activeTab === '1') {
      await getAllHistoricDataForExport().then((data) => {
        extractedData = data;
      });
    }
    if (activeTab === '2') {
      await getAllHourlyWellPumpagesToExport().then((data) => {
        extractedData = data;
      });
    }
    if (activeTab === '3') {
      await getAllDailyWellPumpagesToExport().then((data) => {
        extractedData = data;
      });
    }
    const data = extractData(extractedData, activeTab);
    const exportData = [...data];
    const filterInfo = {
      "Start Date": startDate,
      "Active Tab": activeTab,
      "Selected Parish": selectedParishOption && selectedParishOption.length > 0 ? selectedParishOption.map((x) => x.label).join('| ') : null,
      "Selected Quarter": selectedquarterOption ? selectedquarterOption.value : null,
      "Selected StartDate": selectedStartDateOption ? selectedStartDateOption.value : null,
      "Selected EndDate": selectedEndDateOption ? selectedEndDateOption.value : null,
      "Selected Client": selectedClientOption && selectedClientOption.length > 0 ? selectedClientOption.map((x) => x.label).join('| ') : null,
      "Selected Aquifer": selectedAquiferOption && selectedAquiferOption.length > 0 ? selectedAquiferOption.map((x) => x.label).join('| ') : null,
    };

    const baseFieldNames = ['OwnerName', 'WellNumber', 'Aquifer', 'Parish'];
    const fieldNames = {
      1: baseFieldNames.concat(['Year', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']),
      2: baseFieldNames.concat(['Year', 'Month', 'DayName', ...Array.from({ length: 24 }, (_, i) => `hour${i}`)]),
      3: baseFieldNames.concat(['Year', 'Month', 'DayName', 'FormattedDate', 'DailyPumpage']),
    };

    let csvContent = '';

    const filterHeaders = Object.keys(filterInfo);
    const filterValues = Object.values(filterInfo);
    csvContent += filterHeaders.map((header) => `"${header}"`).join(',') + '\n';
    csvContent += filterValues.map((value) => `"${value}"`).join(',') + '\n';

    csvContent += '\n';

    csvContent += fieldNames[activeTab].map((name) => `"${name}"`).join(',') + '\n'; // Add header row
    exportData.forEach((row) => {
      if (csvContent.length > 0) {
        csvContent += '\n'; // Add a new line between different groups
      }
      const { separator, ...rowData } = row;
      csvContent += Object.values(rowData).map((value) => `"${value}"`).join(',') + '\n'; // Add data row
    });

    const link = document.createElement('a');
    link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
    link.download = `export_tab_${activeTab}.csv`;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const extractData = (pumpages, activeTab) => {
    const extractedData = [];
    pumpages.forEach((p) => {
      switch (parseInt(activeTab, 10)) {
        case 1:
          p.wellPumpageData.forEach((d) => {
            extractedData.push({
              ownerName: p.ownerName,
              wellNumber: p.wellNumber,
              aquifer: p.aquifer,
              parish: p.parish,
              year: d.year,
              january: d.january,
              february: d.february,
              march: d.march,
              april: d.april,
              may: d.may,
              june: d.june,
              july: d.july,
              august: d.august,
              september: d.september,
              october: d.october,
              november: d.november,
              december: d.december,
            });
          });
          break;
        case 2:
          p.wellPumpageHourlyData.forEach((d) => {
            extractedData.push({
              ownerName: p.ownerName,
              wellNumber: p.wellNumber,
              aquifer: p.aquifer,
              parish: p.parish,
              year: d.year,
              monthName: d.monthName,
              dayName: d.dayName,
              ...Array.from({ length: 24 }).reduce((acc, _, i) => {
                acc[`hour${i}`] = d[`hour${i}`];
                return acc;
              }, {}),
            });
          });
          break;
        case 3:
          p.wellPumpageDailyData.forEach((d) => {
            extractedData.push({
              ownerName: p.ownerName,
              wellNumber: p.wellNumber,
              aquifer: p.aquifer,
              parish: p.parish,
              year: d.year,
              monthName: d.monthName,
              dayName: d.dayName,
              formattedDate: d.formattedDate,
              dailyPumpage: d.dailyPumpage,
            });
          });
          break;
        default:
          break;
      }
    });
    return extractedData;
  };

  return (
    <CustomBlock className="content-container--padded">
      {
        isLoading && <Overlay hasLoader />
      }
      <Section isFullWidth>
        <ContentBlock>
          <CustomBlock className="content-container--card-style--with-shadow">
            <ContentHeader
              title="Water Data"
              headerSize="lg"
            />
            <CustomBlock className="flex">
              <Section hasNoContainer>
                <>
                  <ContentBlock cols={4}>
                    <SelectBlock
                      label="Start Date"
                      placeholder="Select year"
                      options={helper.getYears()}
                      closeMenuOnSelect
                      isClearable
                      value={selectedStartDateOption}
                      onChange={(selectedOpt) => {
                        selectedOpt = selectedOpt === null ? null : selectedOpt;
                        setFilterModel({
                          ...filterModel,
                          startDate: (selectedOpt && selectedOpt.value) ?? null
                        });
                        setSelectedStartDateOption(selectedOpt);
                      }}
                    />
                  </ContentBlock>
                  <ContentBlock cols={4}>
                    <SelectBlock
                      label="End Date"
                      placeholder="Select year"
                      options={helper.getYears()}
                      closeMenuOnSelect
                      isClearable
                      value={selectedEndDateOption}
                      onChange={(selectedOpt) => {
                        selectedOpt = selectedOpt === null ? null : selectedOpt;
                        setFilterModel({
                          ...filterModel,
                          endDate: (selectedOpt && selectedOpt.value) ?? null
                        });
                        setSelectedEndDateOption(selectedOpt);
                      }}
                    />
                  </ContentBlock>
                  <ContentBlock cols={4}>
                    <SelectBlock
                      label="Quarter"
                      placeholder="Select quarter"
                      options={quarterOptions}
                      isClearable
                      isMulti
                      closeMenuOnSelect={false}
                      value={selectedquarterOption}
                      onChange={(selectedOpt) => {
                        selectedOpt = selectedOpt === null ? null : selectedOpt;
                        setFilterModel({
                          ...filterModel,
                          quarter: selectedOpt.map((x) => x.value) ?? ''
                        });
                        setSelectedquarterOption(selectedOpt);
                      }}
                    />
                  </ContentBlock>
                  <ContentBlock cols={6}>
                    <InputBlock
                      type="text"
                      label="Well Number"
                      placeholder="Enter Well Number"
                      onChange={(e) => {
                        const wellNumber = e.target.value || null;
                        setFilterModel({
                          ...filterModel,
                          wellNumber
                        });
                      }}
                      value={filterModel.wellNumber || ''}
                    />
                  </ContentBlock>
                  <ContentBlock cols={6}>
                    <SelectBlock
                      label="Parish"
                      placeholder="Select parish"
                      isClearable
                      options={parishes}
                      closeMenuOnSelect={false}
                      isMulti
                      value={selectedParishOption}
                      onChange={(selectedOpt) => {
                        selectedOpt = selectedOpt === null ? null : selectedOpt;
                        setFilterModel({
                          ...filterModel,
                          parishId: selectedOpt.map((x) => x.value) ?? ''
                        });
                        setSelectedParishOption(selectedOpt);
                      }}
                    />
                  </ContentBlock>
                  <ContentBlock cols={6}>
                    <SelectBlock
                      label="Client"
                      placeholder="Select client"
                      options={clients}
                      isClearable
                      isMulti
                      closeMenuOnSelect={false}
                      value={selectedClientOption}
                      onChange={(selectedOpt) => {
                        selectedOpt = selectedOpt === null ? null : selectedOpt;
                        setFilterModel({
                          ...filterModel,
                          clientId: selectedOpt.map((x) => x.value) ?? ''
                        });
                        setSelectedClientOption(selectedOpt);
                      }}
                    />
                  </ContentBlock>
                  <ContentBlock cols={6}>
                    <SelectBlock
                      label="Aquifer"
                      placeholder="Select aquifer"
                      options={aquifers}
                      isClearable
                      isMulti
                      closeMenuOnSelect={false}
                      value={selectedAquiferOption}
                      onChange={(selectedOpt) => {
                        selectedOpt = selectedOpt === null ? null : selectedOpt;
                        setFilterModel({
                          ...filterModel,
                          aquiferId: selectedOpt.map((x) => x.value) ?? ''
                        });
                        setSelectedAquiferOption(selectedOpt);
                      }}
                    />
                  </ContentBlock>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 20 }}>
                    <Button
                      text="Reset"
                      className="primary--bg flex justify-content-center"
                      onClick={handleReset}
                    />
                    &nbsp;
                    <Button
                      text="Search"
                      className="primary--bg ml-0"
                      size="xs"
                      onClick={() => {
                        setActiveTab('1');
                        setApplyFilter(!applyFilter);
                      }}
                    />
                  </div>
                </>
              </Section>

            </CustomBlock>

            <Section hasNoContainer>
              <ContentBlock>
                <Tabs
                  id="tab"
                  className="mb-3"
                  activeKey={parseInt(activeTab, 10)}
                  onSelect={handleSelect}
                >
                  {wellDataTypes && (wellDataTypes).map((type) => (
                    <Tab eventKey={type.id} title={type.name}>
                      {wellPumpages && wellPumpages.length > 0 && (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Button
                            text="Export"
                            className="primary--bg"
                            onClick={() => handleExport(wellPumpages, activeTab)}
                          />
                        </div>
                      )}
                      <div className="scrollable-table-container">
                        <Table
                          striped
                          bordered
                          hover
                          responsive
                        >
                          {wellPumpages && wellPumpages.map((p) => (
                            <>
                              <thead>
                                <tr
                                  className="primary--sbg white--clr"
                                  key={
                                    p.ownerName
                                  }
                                >
                                  <th colSpan={27}>
                                    {`Owner:  ${p.ownerName ?? 'N/A'}`}
                                  </th>
                                </tr>
                              </thead>
                              <thead>
                                <tr
                                  className="blue--bg white--clr"
                                  key={
                                    p.wellNumber
                                  }
                                >
                                  <th colSpan={27}>
                                    {`Well Number:  ${p.wellNumber ?? 'N/A'}`}
                                  </th>
                                </tr>
                              </thead>
                              <thead>
                                <tr
                                  className="info--sbg white--clr"
                                  key={
                                    p.aquifer
                                  }
                                >
                                  <th colSpan={27}>
                                    {`Aquifer:  ${p.aquifer ?? 'N/A'}`}
                                  </th>
                                </tr>
                              </thead>
                              <thead>
                                <tr
                                  className="light-blue--sbg primary--clr"
                                  key={
                                    p.parish
                                  }
                                >
                                  <th colSpan={27}>
                                    {`Parish:  ${p.parish ?? 'N/A'}`}
                                  </th>
                                </tr>
                              </thead>
                              {parseInt(activeTab, 10) === 2 && (
                                <>
                                  <thead>
                                    <tr>
                                      <th>Year</th>
                                      <th>Month</th>
                                      <th style={{ minWidth: '90px' }}>Day</th>
                                      <th>12:00 AM</th>
                                      <th>1:00 AM</th>
                                      <th>2:00 AM</th>
                                      <th>3:00 AM</th>
                                      <th>4:00 AM</th>
                                      <th>5:00 AM</th>
                                      <th>6:00 AM</th>
                                      <th>7:00 AM</th>
                                      <th>8:00 AM</th>
                                      <th>9:00 AM</th>
                                      <th>10:00 AM</th>
                                      <th>11:00 AM</th>
                                      <th>12:00 PM</th>
                                      <th>1:00 PM</th>
                                      <th>2:00 PM</th>
                                      <th>3:00 PM</th>
                                      <th>4:00 PM</th>
                                      <th>5:00 PM</th>
                                      <th>6:00 PM</th>
                                      <th>7:00 PM</th>
                                      <th>8:00 PM</th>
                                      <th>9:00 PM</th>
                                      <th>10:00 PM</th>
                                      <th>11:00 PM</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {p.wellPumpageHourlyData &&
                                      p.wellPumpageHourlyData.map((d) => (
                                        <tr>
                                          <td>{d.year}</td>
                                          <td>{d.monthName}</td>
                                          <td>{d.dayName}</td>
                                          <td>{d.hour0}</td>
                                          <td>{d.hour1}</td>
                                          <td>{d.hour2}</td>
                                          <td>{d.hour3}</td>
                                          <td>{d.hour4}</td>
                                          <td>{d.hour5}</td>
                                          <td>{d.hour6}</td>
                                          <td>{d.hour7}</td>
                                          <td>{d.hour8}</td>
                                          <td>{d.hour9}</td>
                                          <td>{d.hour10}</td>
                                          <td>{d.hour11}</td>
                                          <td>{d.hour12}</td>
                                          <td>{d.hour13}</td>
                                          <td>{d.hour14}</td>
                                          <td>{d.hour15}</td>
                                          <td>{d.hour16}</td>
                                          <td>{d.hour17}</td>
                                          <td>{d.hour18}</td>
                                          <td>{d.hour19}</td>
                                          <td>{d.hour20}</td>
                                          <td>{d.hour21}</td>
                                          <td>{d.hour22}</td>
                                          <td>{d.hour23}</td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </>
                              )}
                              {(parseInt(activeTab, 10) === 1) && (
                                <>
                                  <thead>
                                    <tr>
                                      <th>Year</th>
                                      <th>January</th>
                                      <th>February</th>
                                      <th>March</th>
                                      <th>April</th>
                                      <th>May</th>
                                      <th>June</th>
                                      <th>July</th>
                                      <th>August</th>
                                      <th>September</th>
                                      <th>October</th>
                                      <th>November</th>
                                      <th>December</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {p.wellPumpageData &&
                                      p.wellPumpageData.map((d) => (
                                        <tr>
                                          <td>{d.year}</td>
                                          <td>{d.january}</td>
                                          <td>{d.february}</td>
                                          <td>{d.march}</td>
                                          <td>{d.april}</td>
                                          <td>{d.may}</td>
                                          <td>{d.june}</td>
                                          <td>{d.july}</td>
                                          <td>{d.august}</td>
                                          <td>{d.september}</td>
                                          <td>{d.october}</td>
                                          <td>{d.november}</td>
                                          <td>{d.december}</td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </>
                              )}
                              {parseInt(activeTab, 10) === 3 && (
                                <>
                                  <thead>
                                    <tr>
                                      <th>Year</th>
                                      <th>Month</th>
                                      <th style={{ minWidth: '90px' }}>Day</th>
                                      <th style={{ minWidth: '90px' }}>Date</th>
                                      <th style={{ minWidth: '90px' }}>Daily Pumpage</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {p.wellPumpageDailyData &&
                                      p.wellPumpageDailyData.map((d) => (
                                        <tr>
                                          <td>{d.year}</td>
                                          <td>{d.monthName}</td>
                                          <td>{d.dayName}</td>
                                          <td>{d.formattedDate}</td>
                                          <td>{d.dailyPumpage}</td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </>
                              )}
                            </>
                          ))}
                        </Table>
                      </div>
                      <ReactPaginate
                        pageCount={totalPages}
                        onPageChange={({ selected }) => setPageNumber(selected + 1)}
                        containerClassName="pagination"
                        activeClassName="active"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        disabledClassName="disabled"
                        activeLinkClassName="active-link"
                        previousLabel="Previous"
                        nextLabel="Next"
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    </Tab>
                  ))}
                </Tabs>
              </ContentBlock>
            </Section>
          </CustomBlock>
        </ContentBlock>
      </Section>
    </CustomBlock>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...authRedux.actions,
    ...alert.actions,
    ...confirmModal.actions
  }
)(WellDataPage);