/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { IoMdAddCircle } from 'react-icons/io';
// CUSTOM COMPONENTS
import Overlay from '../../../../../components/Overlay';
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import FormBlock from '../../../../../components/FormBlock';
import CheckboxBlock from '../../../../../components/CheckboxBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import InputBlock from '../../../../../components/InputBlock';
import Button from '../../../../../components/Button';
import ModalBlock from '../../../../../components/ModalBlock';
import SelectBlock from '../../../../../components/SelectBlock';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';
import * as auth from '../../../../../redux/authRedux';
// SERVICES AND HELPERS
import * as clientService from '../../../../../services/management/clientService';
import * as userService from '../../../../../services/management/userService';
import ClientValidator from '../../../../../helpers/validators/management/client/ClientValidator';
import * as helper from '../../../../../helpers/helper';

const initialClientModel = {
  id: 0,
  name: '',
  contactName: '',
  contactPhone: '',
  contactEmail: '',
  contactName2: '',
  contactPhone2: '',
  contactEmail2: '',
  contactName3: '',
  contactPhone3: '',
  contactEmail3: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  isActive: true,
  isSelfSubmitting: false,
  registrationCode: '',
};

const ClientManagementPage = (props) => {
  const {
    showAlert,
    history,
    showConfirmModal,
    hideConfirmModal,
    auth
  } = props;
  const { clientId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [client, setClient] = useState(initialClientModel);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUserOptions, setSelectedUserOptions] = useState([]);

  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    if (clientId) {
      populateForm();
    }
  }, []);

  const getAllUsers = () => {
    userService.getAllUnAssignedUsers().then((res) => {
      setAllUsers(res ? res.map((x) => helper.setToOptionModel(`${x.firstName} ${x.lastName}`, x.id)) : []);
    }).catch((err) => {
      console.log('error getting all users:', err);
    });
  };

  const formik = useFormik({
    initialValues: client,
    validationSchema: ClientValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (clientId) {
        updateClient(values);
      } else {
        createClient(values);
      }
    },
  });

  const populateForm = () => {
    setIsLoading(true);
    getAllUsers();
    clientService.getClientById(clientId).then((res) => {
      setClient(res.client);
      setUsers(res.client.users ? res.client.users : []);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const createClient = (model) => {
    setIsLoading(true);
    clientService.createClient(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.replace(`/management/client/edit/${res.id}`);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const updateClient = (model) => {
    setIsLoading(true);
    clientService.updateClient(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const removeUserAssignment = (id, userId) => {
    hideConfirmModal();
    setIsLoading(true);
    clientService.removeUserAssignment(id, userId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      populateForm();
      handleModalClose();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const options = {
    selectableRows: 'none',
    filter: false,
    sort: false,
    print: false,
    download: false,
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        display: true,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            {
              auth.accessLevel >= 50
              && (
                <Button
                  text="Remove"
                  className="danger--bg"
                  size="xxs"
                  onClick={() => {
                    showConfirmModal({
                      title: 'Remove  User',
                      text: 'Are you sure you want to remove this user from this client?',
                      rightBtnText: 'Confirm',
                      btnAction: () => removeUserAssignment(client.id, client.users[dataIndex].id)
                    });
                  }}
                />
              )
            }
          </CustomBlock>
        )
      }
    },
    {
      name: 'name',
      label: 'User',
    }
  ];

  const handleModalClose = () => {
    setIsModalVisible(false);

    setTimeout(() => {
      setSelectedUserOptions([]);
    }, 100);
  };

  const saveClientUsers = () => {
    if (selectedUserOptions.length <= 0) {
      return;
    }
    setIsLoading(true);
    clientService.assignClientUsers({
      clientId: parseInt(clientId, 10),
      users: selectedUserOptions
    }).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      populateForm();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      handleModalClose();
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <FormBlock className="content-container--card-style--with-shadow" onSubmit={formik.handleSubmit}>
            <ContentHeader
              title="Well Owner Details"
              headerSize="lg"
            />

            <Section hasNoContainer>
              <ContentBlock>
                <ContentHeader
                  title="Basic Information"
                  headerSize="md"
                  className="alt-font fw-600 pb-15"
                />
              </ContentBlock>

              <ContentBlock cols={6}>
                <InputBlock
                  label="Organization Name"
                  isRequired
                  isDisabled={auth.accessLevel < 50}
                  errorMessage={formik.errors.name}
                  inputState={`${helper.getInputClasses(formik, 'name')}`}
                  {...formik.getFieldProps('name')}
                />
              </ContentBlock>
              <ContentBlock cols={6}>
                <InputBlock
                  label="Owner Id"
                  isRequired
                  isDisabled={auth.accessLevel < 50}
                  errorMessage={formik.errors.ownerId}
                  inputState={`${helper.getInputClasses(formik, 'ownerId')}`}
                  {...formik.getFieldProps('ownerId')}
                />
              </ContentBlock>

              {
                auth.accessLevel >= 50
                && (
                  <ContentBlock>
                    <CheckboxBlock
                      label="Is active?"
                      id="isActive"
                      {...formik.getFieldProps('isActive')}
                      isChecked={formik.values.isActive}
                    />
                  </ContentBlock>
                )
              }
              &nbsp;
              {
                auth.accessLevel >= 50
                && (
                  <ContentBlock>
                    <CheckboxBlock
                      label="Is self submitting?"
                      id="isSelfSubmitting"
                      {...formik.getFieldProps('isSelfSubmitting')}
                      isChecked={formik.values.isSelfSubmitting}
                    />
                  </ContentBlock>
                )
              }
              <ContentBlock className="mt-30">
                <ContentHeader
                  title="Contact Information"
                  headerSize="md"
                  className="alt-font fw-600 alt--clr pb-15"
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Primary Contact Name"
                  placeholder="e.g. John Doe"
                  errorMessage={formik.errors.contactName}
                  inputState={`${helper.getInputClasses(formik, 'contactName')}`}
                  {...formik.getFieldProps('contactName')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Primary Contact Phone"
                  placeholder="e.g. (999)999-9999"
                  mask="(999)999-9999"
                  errorMessage={formik.errors.contactPhone}
                  inputState={`${helper.getInputClasses(formik, 'contactPhone')}`}
                  {...formik.getFieldProps('contactPhone')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Primary Contact Email"
                  placeholder="e.g. john.doe@gmail.com"
                  errorMessage={formik.errors.contactEmail}
                  inputState={`${helper.getInputClasses(formik, 'contactEmail')}`}
                  {...formik.getFieldProps('contactEmail')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Secondary Person Name"
                  placeholder="e.g. John Doe"
                  errorMessage={formik.errors.contactName2}
                  inputState={`${helper.getInputClasses(formik, 'contactName2')}`}
                  {...formik.getFieldProps('contactName2')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Secondary Person Phone"
                  placeholder="e.g. (999)999-9999"
                  mask="(999)999-9999"
                  errorMessage={formik.errors.contactPhone2}
                  inputState={`${helper.getInputClasses(formik, 'contactPhone2')}`}
                  {...formik.getFieldProps('contactPhone2')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Secondary Person Email"
                  placeholder="e.g. john.doe@gmail.com"
                  errorMessage={formik.errors.contactEmail2}
                  inputState={`${helper.getInputClasses(formik, 'contactEmail2')}`}
                  {...formik.getFieldProps('contactEmail2')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Tertiary Person Name"
                  placeholder="e.g. John Doe"
                  errorMessage={formik.errors.contactName3}
                  inputState={`${helper.getInputClasses(formik, 'contactName3')}`}
                  {...formik.getFieldProps('contactName3')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Tertiary Person Phone"
                  placeholder="e.g. (999)999-9999"
                  mask="(999)999-9999"
                  errorMessage={formik.errors.contactPhone3}
                  inputState={`${helper.getInputClasses(formik, 'contactPhone3')}`}
                  {...formik.getFieldProps('contactPhone3')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Tertiary Person Email"
                  placeholder="e.g. john.doe@gmail.com"
                  errorMessage={formik.errors.contactEmail3}
                  inputState={`${helper.getInputClasses(formik, 'contactEmail3')}`}
                  {...formik.getFieldProps('contactEmail3')}
                />
              </ContentBlock>

              <ContentBlock className="mt-30">
                <ContentHeader
                  title="Location Information"
                  headerSize="md"
                  className="alt-font fw-600 alt--clr pb-15"
                />
              </ContentBlock>

              <ContentBlock cols={3}>
                <InputBlock
                  label="Street Address"
                  placeholder="e.g. 3900 N Causeway Blvd"
                  isRequired
                  errorMessage={formik.errors.address1}
                  inputState={`${helper.getInputClasses(formik, 'address1')}`}
                  {...formik.getFieldProps('address1')}
                />
              </ContentBlock>

              <ContentBlock cols={3}>
                <InputBlock
                  label="Suite/Unit/Bldg No."
                  placeholder="e.g. #1350"
                  errorMessage={formik.errors.address2}
                  inputState={`${helper.getInputClasses(formik, 'address2')}`}
                  {...formik.getFieldProps('address2')}
                />
              </ContentBlock>

              <ContentBlock cols={2}>
                <InputBlock
                  label="City"
                  placeholder="e.g. New Orleans"
                  isRequired
                  errorMessage={formik.errors.city}
                  inputState={`${helper.getInputClasses(formik, 'city')}`}
                  {...formik.getFieldProps('city')}
                />
              </ContentBlock>

              <ContentBlock cols={2}>
                <InputBlock
                  label="State"
                  placeholder="e.g. Louisiana"
                  isRequired
                  errorMessage={formik.errors.state}
                  inputState={`${helper.getInputClasses(formik, 'state')}`}
                  {...formik.getFieldProps('state')}
                />
              </ContentBlock>

              <ContentBlock cols={2}>
                <InputBlock
                  label="Zip Code"
                  placeholder="e.g. 70002"
                  mask="99999"
                  isRequired
                  errorMessage={formik.errors.zipCode}
                  inputState={`${helper.getInputClasses(formik, 'zipCode')}`}
                  {...formik.getFieldProps('zipCode')}
                />
              </ContentBlock>

              <ContentBlock cols={2}>
                <InputBlock
                  label="Registration Code"
                  isRequired
                  isDisabled={clientId}
                  errorMessage={formik.errors.registrationCode}
                  inputState={`${helper.getInputClasses(formik, 'registrationCode')}`}
                  {...formik.getFieldProps('registrationCode')}
                />
              </ContentBlock>

              {
                (clientId)
                && (
                  <ContentBlock className="mt-60">
                    {
                      auth.accessLevel >= 50
                      && (
                        <ContentHeader
                          title="Client Users"
                          headerSize="md"
                          className="secondary--clr alt-font fw-600 pb-0"
                          primaryButtonText="Assign User"
                          primaryButtonIconLeft={
                            <IoMdAddCircle className={iconColor} size={iconSize} />
                          }
                          primaryButtonOnClick={() => {
                            setIsModalVisible(true);
                          }}
                        />
                      )
                    }
                    {
                      auth.accessLevel >= 50
                      && (
                        <CustomBlock>
                          <MUIDataTable
                            data={users}
                            columns={columns}
                            options={options}
                          />
                        </CustomBlock>
                      )
                    }
                  </ContentBlock>
                )
              }

              <ContentBlock className="content-container--actions flex-end">
                <Button
                  text="Cancel"
                  className="danger--bg"
                  onClick={() => history.goBack()}
                />

                {
                  auth.accessLevel >= 40
                  && (
                    <Button
                      text={clientId ? 'Save Changes' : 'Create'}
                      className="primary--bg"
                      onClick={formik.handleSubmit}
                    />
                  )
                }
              </ContentBlock>
            </Section>
          </FormBlock>
        </Section>
      </CustomBlock>

      {/* ASSIGNMENT MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={isModalVisible}
        size="md"
        contentHeader="Assign Users"
        primaryModalActionText="Add"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={saveClientUsers}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={handleModalClose}
      >
        <FormBlock>
          <Section hasNoContainer>
            <ContentBlock>
              {
                allUsers && allUsers.length > 0 ? (
                  <SelectBlock
                    label="Select Users"
                    options={allUsers}
                    isRequired
                    isMulti
                    closeMenuOnSelect={false}
                    value={selectedUserOptions}
                    onChange={(selectedOpt) => {
                      selectedOpt = selectedOpt === null ? [] : selectedOpt;
                      setSelectedUserOptions(selectedOpt);
                    }}
                  />
                )
                  : (
                    <strong>No Users Available.</strong>
                  )
              }

            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alert.actions,
  ...confirmModal.actions
})(ClientManagementPage);