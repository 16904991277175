/* eslint-disable */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as Yup from 'yup';
// COMPONENTS
import Table from 'react-bootstrap/Table';
import { BsFillCreditCard2BackFill, BsPencilSquare } from 'react-icons/bs';
import { FaSync } from 'react-icons/fa';
import { RiPenNibFill, RiMailAddFill } from 'react-icons/ri';
import CreditCardPaymentModule from './CreditCardPaymentModule';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import ModalBlock from '../../../../../components/ModalBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import Button from '../../../../../components/Button';
import InputBlock from '../../../../../components/InputBlock';
import CheckboxBlock from '../../../../../components/CheckboxBlock';
import Overlay from '../../../../../components/Overlay';
import InfoBlock from '../../../../../components/InfoBlock';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';
// SERVICES AND HELPERS
import * as helper from '../../../../../helpers/helper';
import * as invoiceService from '../../../../../services/payment/invoiceService';
import constants from '../../../../../constants/constants';
import { display } from '@mui/system';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const intialEditModel = {
  id: 0,
  wellNo: '',
  month1: '',
  pumpageMonth1: '',
  month2: '',
  pumpageMonth2: '',
  month3: '',
  pumpageMonth3: '',
};

const MakePaymentPage = (props) => {
  const {
    auth,
    showAlert,
    showConfirmModal,
    hideConfirmModal,
    history
  } = props;
  const { invoiceId, clientId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatingPumpage, setIsUpdatingPumpage] = useState(false);
  const [invoice, setInvoice] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [isCreditCardModalVisible, setIsCreditCardModalVisible] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editModel, setEditModel] = useState(intialEditModel);
  const [isPayByCheckModalVisible, setIsPayByCheckModalVisible] = useState(false);
  const [adjustments, setAdjustments] = useState([]);
  const [newAdjustment, setNewAdjustment] = useState({ value: '', reason: '' });
  const [editingAdjustment, setEditingAdjustment] = useState(null);

  const iconSize = 20;
  const inputIconColor = 'white--clr';

  const creditCardIcon = <BsFillCreditCard2BackFill size={iconSize} className={inputIconColor} />;
  const mailIcon = <RiMailAddFill size={iconSize} className={inputIconColor} />;
  const checkIcon = <RiPenNibFill size={iconSize} className={inputIconColor} />;
  const syncIcon = <FaSync size={iconSize} className={inputIconColor} />;

  useEffect(() => {
    fetchInvoice();
  }, []);

  const editFormik = useFormik({
    initialValues: editModel,
    validationSchema: Yup.object().shape({
      id: Yup.number().required(),
      pumpageMonth1: Yup.number('Please enter valid pumpage').required(),
      pumpageMonth2: Yup.number('Please enter valid pumpage').required(),
      pumpageMonth3: Yup.number('Please enter valid pumpage').required(),
    }),
    enableReinitialize: true,
    // onSubmit: (values, { setSubmitting }) => {
    //   // setSubmitting(true);
    //   // updatePumpageData(values);
    // },
  });

  const fetchInvoice = () => {
    setIsLoading(true);
    invoiceService.getInvoiceById(clientId ?? auth.clientIds, invoiceId).then((res) => {
      setInvoice(res);
      const result = _(res.invoiceItems)
        .groupBy('parish')
        .map((items, parish) => {
          const useString = _(items)
            .uniqBy('use')
            .map('use')
            .join(', ');
          return { parish, use: useString, invoiceItems: items };
        })
        .value();
      setInvoiceItems(result);
      setAdjustments(res.adjustments || []);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const fetchPaymentIntentForTransaction = () => {
    setIsLoading(true);
    invoiceService.createPaymentIntent(auth.clientIds, invoiceId).then((res) => {
      setIsCreditCardModalVisible(true);
      setPaymentIntent(res);
      setTotalAmount(res.amount / 100);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const cancelPayment = () => {
    if (paymentIntent) {
      setIsLoading(true);

      invoiceService.cancelPaymentIntent(auth.clientIds, paymentIntent.id).then(() => {

      }).catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  const handlePaymentModalOnHide = () => {
    cancelPayment();
    setIsCreditCardModalVisible(false);
  };

  const handleEditModalClose = () => {
    setIsEditModalVisible(false);
    setTimeout(() => {
      setEditModel(intialEditModel);
    }, 250);
  };

  // const captureEditModel = (model) => {
  //   setEditModel({
  //     id: model.id,
  //     wellNo: model.ownerNo,
  //     month1: helper.getQuarterDay(invoice.quater, 1),
  //     pumpageMonth1: model.month1Pumpage,
  //     month2: helper.getQuarterDay(invoice.quater, 2),
  //     pumpageMonth2: model.month2Pumpage,
  //     month3: helper.getQuarterDay(invoice.quater, 3),
  //     pumpageMonth3: model.month3Pumpage,
  //   });

  //   setIsEditModalVisible(true);
  // };

  // const updatePumpageData = (model) => {
  //   setIsLoading(true);
  //   handleEditModalClose();

  //   invoiceService.updateInvoicePumpage(auth.clientIds ? auth.clientIds : clientId, model).then((res) => {
  //     fetchInvoice();
  //     showAlert({ text: res.message, state: 'success' });
  //   }).catch((ex) => {
  //     showAlert({ text: ex.message, state: 'error' });
  //   }).finally(() => {
  //     setIsLoading(false);
  //   });
  // };

  const recordClientPayment = () => {
    setIsLoading(true);
    hideConfirmModal();

    invoiceService.recordPaymentByAdmin(invoiceId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/invoices');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const updateInvoiceItemsPumpage = () => {
    if (isUpdatingPumpage) return;
    setIsUpdatingPumpage(true);
    invoiceService.updateInvoiceItemsPumpageManually(clientId ?? 0, invoiceId)
      .then((res) => {
        showAlert({ text: res.message, state: 'success' });
        fetchInvoice();
      })
      .catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      })
      .finally(() => {
        setIsUpdatingPumpage(false);
      });
  };

  const handlePrintButtonClick = () => {
    const reportUrl = `${constants.API_URL}Report/GetInvoiceReport?InvoiceId=${parseInt(invoiceId, 10)}`;
    window.open(reportUrl, '_blank', 'noopener,noreferrer');
  };

  const startEditAdjustment = (adjustment) => {
    setEditingAdjustment(adjustment);
  };

  const cancelEditAdjustment = () => {
    setEditingAdjustment(null);
  };

  const saveEditAdjustment = () => {
    setIsLoading(true);
    invoiceService.updateAdjustment(editingAdjustment.id, editingAdjustment)
      .then((res) => {
        setAdjustments(adjustments.map(adj => adj.id === res.id ? res : adj));
        setEditingAdjustment(null);
        showAlert({ text: 'Adjustment updated successfully', state: 'success' });
      })
      .catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const removeAdjustment = (adjustmentId) => {
    setIsLoading(true);
    invoiceService.removeAdjustment(adjustmentId)
      .then((res) => {
        setAdjustments(adjustments.filter(adj => adj.id !== adjustmentId));
        showAlert({ text: res.message, state: 'success' });
      })
      .catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const calculateTotalAdjustment = () => {
    return adjustments.reduce((total, adj) => total + (Number(adj.value) || 0), 0);
  };

  const calculateGrandTotal = () => {
    const feeAmount = invoice?.feeAmount || 0;
    const lateFeeAmount = invoice?.lateFeeAmount || 0;
    const totalAdjustment = calculateTotalAdjustment();

    return feeAmount + totalAdjustment + lateFeeAmount;
  };

  const addAdjustment = () => {
    if (newAdjustment.value !== 0 && newAdjustment.reason.trim() !== '') {
      setIsLoading(true);
      invoiceService.addAdjustment(invoiceId, newAdjustment)
        .then((res) => {
          setAdjustments([...adjustments, res]);
          setNewAdjustment({ value: '', reason: '' });
          showAlert({ text: 'Adjustment added successfully', state: 'success' });
        })
        .catch((ex) => {
          showAlert({ text: ex.message, state: 'error' });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleAdjustmentValueChange = (e, type) => {
    const value = e.target.value;
    const parsedValue = parseFloat(value);

    if (!isNaN(parsedValue) || value === '-') {
      if (type === 'new') {
        setNewAdjustment({ ...newAdjustment, value: value === '-' ? '-' : parsedValue });
      } else if (type === 'edit') {
        setEditingAdjustment({ ...editingAdjustment, value: value === '-' ? '-' : parsedValue });
      }
    }
  };

  const sendInvoiceEmail = (invoiceId) => {
    setIsLoading(true);
    invoiceService.sendInvoiceEmail(invoiceId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const confirmPayByCheck = () => {
    showConfirmModal({
      title: 'Confirm Pay by Check',
      text: 'Are you sure you want to confirm payment by check for this invoice?',
      btnAction: () => {
        hideConfirmModal();
        sendConfirmationEmailToAdmin();
      },
      rightBtnText: 'Confirm',
      cancelText: 'Cancel'
    });
  };

  const sendConfirmationEmailToAdmin = () => {
    setIsLoading(true);
    invoiceService.sendConfirmationEmail(invoiceId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      setIsPayByCheckModalVisible(false);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const toggleIsVisible = () => {
    const updatedInvoice = { ...invoice, isVisible: !invoice.isVisible };
    setInvoice(updatedInvoice);
    updateIsVisible(updatedInvoice);
  };

  const updateIsVisible = (updatedInvoice) => {
    invoiceService.updateInvoiceVisibility(updatedInvoice.id, updatedInvoice.isVisible)
      .then((res) => {
        showAlert({ text: res.message, state: 'success' });
      })
      .catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      });
  };

  const confirmSendInvoiceEmail = (invoiceId) => {
    showConfirmModal({
      title: 'Send Invoice Email',
      text: 'Are you sure you want to send this invoice email?',
      btnAction: () => {
        sendInvoiceEmail(invoiceId);
        hideConfirmModal();
      },
      rightBtnText: 'Send',
      cancelText: 'Cancel'
    });
  };

  return (
    <CustomBlock className="content-container--padded">
      {
        (isLoading || isUpdatingPumpage) && <Overlay hasLoader />
      }
      <Section isFullWidth>
        <ContentBlock>
          <CustomBlock className="content-container--card-style--with-shadow">

            <ContentHeader
              title={`${invoice.invoiceNumber ?? ''}`}
              headerSize="lg"
              primaryButtonText="Print"
              primaryButtonOnClick={handlePrintButtonClick}
              secondaryButtonText={(!invoice.hasPaid && auth.accessLevel >= 50) ? "Regenerate Invoice" : ''}
              secondaryButtonIcon={syncIcon}
              secondaryButtonOnClick={() => updateInvoiceItemsPumpage()}
              secondaryButtonDisabled={isUpdatingPumpage}
            />
            <Section hasNoContainer>
              {
                auth.accessLevel >= 50 &&
                (
                  <CheckboxBlock
                    label="Is Visible?"
                    id="isVisible"
                    isChecked={invoice.isVisible}
                    onChange={toggleIsVisible}
                  />
                )
              }
              {/* INVOICE SENDER BLOCK */}
              <ContentBlock cols={3}>
                <CustomBlock className="content-container--card-style--with-shadow mb-15">
                  <ContentHeader
                    title="Invoice To:"
                    headerSize="md"
                    containerClassName="pb-10"
                  />
                  <CustomBlock className="ml-5">
                    <p>
                      {invoice.client}
                    </p>
                   {
                    auth.accessLevel >= 50 ? (
                      <p>
                      {invoice.invoiceOwnerName}
                    </p>
                    ) : (
                      <p>
                      {auth.user.firstName}
                      {' '}
                      {auth.user.lastName}
                    </p>
                    )
                   }
                    <p>
                      {invoice.address}
                    </p>
                    <p>
                      {invoice.cityStateZip}
                    </p>
                  </CustomBlock>
                </CustomBlock>
              </ContentBlock>

              {/* INVOICE RECIPIENT BLOCK */}
              <ContentBlock cols={5}>
                <CustomBlock className="">
                  <Section hasNoContainer>
                    <ContentBlock className="content-container--card-style--with-shadow mb-15">
                      <ContentHeader
                        isTitleCentered
                        title="Capital Area Groundwater Conservation Commission"
                        headerSize="md"
                        containerClassName="pb-10"
                      />
                      <CustomBlock className="text-center">
                        <p>3074 Westfork Drive, Suite A</p>
                        <p>Baton Rouge, LA 70816</p>
                        <p className="mt-10">Phone: (225)293-7370</p>
                        <p>Fax: (225)293-7373</p>
                      </CustomBlock>
                    </ContentBlock>
                  </Section>
                </CustomBlock>
              </ContentBlock>

              {/* INVOICE DATE BLOCK */}
              <ContentBlock cols={4}>
                <CustomBlock className="content-container--card-style--with-shadow mb-15">
                  <ContentHeader
                    title="Billing Date:"
                    headerSize="md"
                    containerClassName="pb-10"
                  />
                  <CustomBlock className="ml-5">
                    <p>
                      <span className="fw-600">{invoice.billingDate}</span>
                    </p>

                    <CustomBlock className="mt-15">
                      <InfoBlock isCentered type={invoice.hasPaid ? 'info' : 'danger'}>
                        <p>
                          Payment Deadline:
                          {' '}
                          <span>{invoice.dueDate}</span>
                        </p>
                      </InfoBlock>
                    </CustomBlock>
                  </CustomBlock>
                </CustomBlock>
              </ContentBlock>

              {/* LATE FEE INFO BLOCK */}
              <ContentBlock>
                {
                  (invoice.dueDate && invoice.lateFeeAmount > 0 && !invoice.hasPaid)
                  && (
                    <InfoBlock isCentered type="danger">
                      <p>
                        Your payment is
                        {' '}
                        <span>{helper.daysDifference(invoice.dueDate, new Date())}</span>
                        {' '}
                        days overdue. Late fees apply. Your current late fee is
                        {' '}
                        <span>{helper.currencyFormat(invoice.lateFeeAmount)}</span>
                      </p>
                    </InfoBlock>
                  )
                }
              </ContentBlock>

              <ContentBlock>
                {
                  (invoice.dueDate && invoice.lateFeeProjectedAmount > 0 && !invoice.hasPaid) && (
                    <InfoBlock isCentered type="warning">
                      <p>
                        The extended payment deadline is
                        {' '}
                        <span>{
                          (() => {
                            const tempDueDate = new Date(invoice.dueDate);
                            const newDueDate = new Date(tempDueDate.setDate(tempDueDate.getDate() + 30));
                            const today = new Date();
                            const timeDiff = newDueDate - today;
                            const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
                            return daysDiff;
                          })()
                        }</span>
                        {' '}
                        days away. Post this period, additional late fees will apply. Current accrued late fees amount to
                        {' '}
                        <span>{helper.currencyFormat(invoice.lateFeeProjectedAmount)}</span>.
                      </p>
                    </InfoBlock>
                  )
                }
              </ContentBlock>

              {
                invoice.hasPaid
                && (
                  <ContentBlock>
                    <InfoBlock isCentered type="success">
                      <p>This invoice has been paid</p>
                    </InfoBlock>
                  </ContentBlock>
                )
              }

              {/* UPDATE PUMPAGE RATE INFO BLOCK */}
              {
                !invoice.hasPaid
                && (
                  <ContentBlock>
                    <InfoBlock
                      isCentered
                      lineOne="Please review the invoice and complete the payment."
                    />
                  </ContentBlock>
                )
              }

              {/* WELL INVOICE TABLE */}
              <ContentBlock>
                <Table
                  striped
                  bordered
                  hover
                  className="text-center"
                >
                  <thead>
                    <tr>
                      <th colSpan={1}>WELL NO. AND LOCATION</th>
                      <th colSpan={5}>PUMPAGE IN THOUSANDS OF GALLONS</th>
                    </tr>
                  </thead>
                  {
                    invoiceItems.map((item) => (
                      <>
                        <thead key={item.parish}>
                          <tr className="primary--sbg white--clr">
                            <th>Parish</th>
                            <th>Year</th>
                            <th>Quarter</th>
                            <th colSpan={3}>Use</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{item.parish}</td>
                            <td>{invoice.year}</td>
                            <td>{invoice.quater}</td>
                            <td colSpan={3}>{item.use}</td>
                          </tr>
                        </tbody>
                        <thead>
                          <tr className="success--sbg white--clr">
                            <th>Well No.</th>
                            <th colSpan={2}>Well Depth</th>
                            <th>{helper.getQuarterDay(invoice.quater, 1)}</th>
                            <th>{helper.getQuarterDay(invoice.quater, 2)}</th>
                            <th>{helper.getQuarterDay(invoice.quater, 3)}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            _.sortBy(item.invoiceItems, lineItem => lineItem.ownerNo).map((lineItem) => (
                              <tr key={lineItem.id}
                                style={{ backgroundColor: lineItem.flag === 'E' ? '#ffcccc' : 'transparent' }}
                                title={lineItem.flag === 'E' ? 'This Well is Exempt. Not included in the Total.' : ''}
                              >
                                <td>{lineItem.ownerNo}</td>
                                <td colSpan={2}>{lineItem.depth}</td>
                                <td className="cursor-pointer"
                                // onDoubleClick={() => captureEditModel(lineItem)}
                                >{lineItem.month1Pumpage}
                                </td>
                                <td className="cursor-pointer"
                                // onDoubleClick={() => captureEditModel(lineItem)}
                                >{lineItem.month2Pumpage}
                                </td>
                                <td className="cursor-pointer"
                                // onDoubleClick={() => captureEditModel(lineItem)}
                                >{lineItem.month3Pumpage}
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </>
                    ))
                  }
                  <thead>
                    <tr className="primary--sbg">
                      <td colSpan={6}> </td>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <td colSpan={3}>Subtotal Pumpage (by month)</td>
                      <td>
                        <strong>
                          {invoice.invoiceItems && invoice.invoiceItems
                            .filter((v) => v.flag !== "E")
                            .reduce((a, v) => a + v.month1Pumpage, 0)}
                        </strong>
                      </td>
                      <td>
                        <strong>
                          {invoice.invoiceItems && invoice.invoiceItems
                            .filter((v) => v.flag !== "E")
                            .reduce((a, v) => a + v.month2Pumpage, 0)}
                        </strong>
                      </td>
                      <td>
                        <strong>
                          {invoice.invoiceItems && invoice.invoiceItems
                            .filter((v) => v.flag !== "E")
                            .reduce((a, v) => a + v.month3Pumpage, 0)}
                        </strong>
                      </td>
                    </tr>
                  </thead>


                  <thead>
                    <tr>
                      <td colSpan={3}>
                        Total Pumpage (QTR
                        {' '}
                        {invoice.quater}
                        )
                      </td>
                      <td colSpan={3}>
                        <i>
                          {invoice.totalGallons}
                          {' '}
                          thousand gallons
                        </i>
                      </td>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <td colSpan={4}></td>
                      <td>Amount Due: Total Pumpage * $0.065</td>
                      <td colSpan={2}>
                        <strong>
                          {helper.currencyFormat(invoice.feeAmount)}
                        </strong>
                      </td>
                    </tr>
                  </thead>
                  <>
                    <thead>
                      <tr className="primary--sbg white--clr">
                        <td colSpan={6}>Adjustments</td>
                      </tr>
                    </thead>
                    <tbody>
                      {adjustments.map((adj) => (
                        <tr key={adj.id}>
                          <td colSpan={3}>
                            {editingAdjustment && editingAdjustment.id === adj.id ? (
                              <InputBlock
                                value={editingAdjustment?.reason || ''}
                                onChange={(e) => setEditingAdjustment({ ...editingAdjustment, reason: e.target.value })}
                              />
                            ) : (
                              adj.reason
                            )}
                          </td>
                          <td colSpan={auth.accessLevel >= 50 ? 2 : 3}>
                            {editingAdjustment && editingAdjustment.id === adj.id ? (
                              <InputBlock
                                type="number"
                                value={editingAdjustment.value}
                                onChange={(e) => handleAdjustmentValueChange(e, 'edit')}
                                step="0.01"
                                placeholder="Adjustment Value (e.g., -50.00)"
                              />
                            ) : (
                              helper.currencyFormat(adj.value)
                            )}
                          </td>
                          {
                            auth.accessLevel >= 50 &&
                            <td colSpan={1}>
                              {editingAdjustment && editingAdjustment.id === adj.id ? (
                                <div className='d-flex' style={{ justifyContent: 'space-evenly' }}>
                                  <Button
                                    onClick={saveEditAdjustment}
                                    size='xxs'
                                    text="Save"
                                    className="success--bg"
                                  />
                                  <Button
                                    onClick={cancelEditAdjustment}
                                    size='xxs'
                                    text="Cancel"
                                    className="danger--bg"
                                  />
                                </div>
                              ) : (
                                <div className='d-flex' style={{ justifyContent: 'space-evenly' }}>
                                  <Button
                                    onClick={() => startEditAdjustment(adj)}
                                    size='xxs'
                                    text="Edit"
                                    className="primary--bg"
                                    icon={<BsPencilSquare />}
                                  />
                                  <Button
                                    onClick={() => removeAdjustment(adj.id)}
                                    size='xxs'
                                    text="Remove"
                                    className="danger--bg"
                                  />
                                </div>
                              )}
                            </td>
                          }

                        </tr>
                      ))}
                      {
                        auth.accessLevel >= 50 &&
                        <tr>
                          <td colSpan={3}>
                            <InputBlock
                              value={newAdjustment.reason}
                              onChange={(e) => setNewAdjustment({ ...newAdjustment, reason: e.target.value })}
                              placeholder="Reason for adjustment"
                            />
                          </td>
                          <td colSpan={2}>
                            <InputBlock
                              type="number"
                              value={newAdjustment.value}
                              onChange={(e) => handleAdjustmentValueChange(e, 'new')}
                              step="0.01"
                              placeholder="Adjustment Value (e.g., -50.00)"
                            />
                          </td>
                          <td colSpan={1} style={{ position: 'relative', height: '100%', minHeight: '50px' }}>
                            <div style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)'
                            }}>
                              <Button
                                onClick={addAdjustment}
                                size='xxs'
                                text="Add"
                                className="success--bg"
                              />
                            </div>

                          </td>

                        </tr>
                      }

                    </tbody>
                  </>
                  <thead>
                    <tr>
                      <td colSpan={4}></td>
                      <td>Total Adjustments</td>
                      <td colSpan={2}>
                        <strong>
                          {helper.currencyFormat(calculateTotalAdjustment())}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        Late Fees
                      </td>
                      <td colSpan={2}>
                        <strong>
                          {helper.currencyFormat(invoice.lateFeeAmount)}
                        </strong>
                      </td>
                      <td>Grand Total</td>
                      <td>
                        <strong>
                          {helper.currencyFormat(calculateGrandTotal())}
                        </strong>
                      </td>
                    </tr>
                  </thead>


                </Table>

                <ContentBlock>
                  <InfoBlock isCentered>
                    <p>
                      Amount Due Calculation:
                      {' '}
                      <span>TOTAL PUMPAGE x $0.065 + ADJUSTMENTS + LATE FEES = Grand Total</span>
                    </p>
                    {/* <p>
                      Base Amount: {helper.currencyFormat(invoice?.feeAmount || 0)}
                    </p> */}
                    <p>
                      Total Adjustments: {helper.currencyFormat(calculateTotalAdjustment())}
                    </p>
                    <p>
                      Late Fees: {helper.currencyFormat(invoice?.lateFeeAmount || 0)}
                    </p>
                    <p>
                      Grand Total: {helper.currencyFormat(calculateGrandTotal())}
                    </p>
                    <p>(Charges are based on $65.00 per million gallons. Late fee is 1.5% of total due multiplied by the number of days late)</p>
                  </InfoBlock>
                </ContentBlock>

                {
                  !invoice.hasPaid && (
                    auth.accessLevel >= 50 ? (
                      <ContentBlock className="payment-btns content-container--actions flex-end">
                        <Button
                          text="Send Email"
                          className="secondary--bg ml-2 content-header"
                          onClick={() => confirmSendInvoiceEmail(invoiceId)}
                        />
                        <Button
                          text="Record Payment"
                          className="primary--bg"
                          iconLeft={creditCardIcon}
                          onClick={() => {
                            showConfirmModal({
                              title: 'Record Payment',
                              text: 'Are you sure you want to record payment on this invoice for this client?',
                              rightBtnText: 'Confirm',
                              btnAction: () => {
                                recordClientPayment();
                              }
                            });
                          }}
                        />

                      </ContentBlock>
                    ) : (
                      <ContentBlock className="payment-btns content-container--actions flex-end">
                        <Button
                          text="Pay Online"
                          className="success--bg"
                          iconLeft={creditCardIcon}
                          onClick={() => {
                            fetchPaymentIntentForTransaction();
                          }}
                        />

                        <Button
                          text="Pay By Check"
                          className="primary--bg"
                          iconLeft={checkIcon}
                          onClick={() => setIsPayByCheckModalVisible(true)}
                        />
                      </ContentBlock>
                    )
                  )
                }
              </ContentBlock>
            </Section>
          </CustomBlock>
        </ContentBlock>
      </Section>

      {/* CREDIT CARD MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={isCreditCardModalVisible}
        size="lg"
        contentHeader="Pay Invoice"
        onHide={handlePaymentModalOnHide}
      >
        <CustomBlock>
          <InfoBlock>
            <p>
              <span>2.9% + 30¢</span>
              {' '}
              charge will be applied to all credit card transactions
            </p>
            <p>
              Total Amount Due: <span>{helper.currencyFormat(totalAmount)}</span>
            </p>
          </InfoBlock>
        </CustomBlock>

        <CustomBlock>
          {
            paymentIntent && (
              <Elements
                stripe={stripePromise}
                options={{ clientSecret: paymentIntent.client_secret }} // paymentIntent.clientSecret }}
              >
                <CreditCardPaymentModule
                  paymentIntent={paymentIntent}
                  invoiceId={invoiceId}
                  {...props}
                />
              </Elements>
            )
          }
        </CustomBlock>
      </ModalBlock>

      {/* PAY BY CHECK MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={isPayByCheckModalVisible}
        size="lg"
        contentHeader="Pay Invoice by Check"
        onHide={() => {
          setIsPayByCheckModalVisible();
        }}
      >
        <CustomBlock>
          <h5>Please make the following payment to:</h5>

          <CustomBlock className="mt-10">
            <InfoBlock>
              <p>Capital Area Groundwater Conservation District</p>
              <p>3074 Westfork Drive, Suite A</p>
              <p>Baton Rouge, LA</p>
              <p>70816</p>
            </InfoBlock>
          </CustomBlock>
        </CustomBlock>
        <div>
          <hr className="grey--clr" />
          <p>We will record your payment upon receipt and confirmation. If you have any questions, please reach out to us. Thank you.</p>
        </div>
        <ContentBlock className="payment-btns content-container--actions flex-end">
          <Button
            text="Confirm"
            className="success--bg"
            iconLeft={mailIcon}
            isLoading={isLoading}
            onClick={confirmPayByCheck}
          />
        </ContentBlock>
      </ModalBlock>

      {/* UPDATE PUMPAGE MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={isEditModalVisible}
        size="md"
        contentHeader="Update Pumpage"
        primaryModalActionText="Update"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={editFormik.handleSubmit}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={handleEditModalClose}
      >
        <Section hasNoContainer>
          <ContentBlock cols={12}>
            <InputBlock
              label="Well No"
              isRequired
              value="22033-464"
              isDisabled
              {...editFormik.getFieldProps('wellNo')}
            />
          </ContentBlock>

          <ContentBlock cols={12}>
            <InputBlock
              label={`Reported Pumpage: ${editFormik.values.month1}`}
              isRequired
              errorMessage={editFormik.errors.pumpageMonth1}
              inputState={`${helper.getInputClasses(editFormik, 'pumpageMonth1')}`}
              {...editFormik.getFieldProps('pumpageMonth1')}
            />
          </ContentBlock>

          <ContentBlock cols={12}>
            <InputBlock
              label={`Reported Pumpage: ${editFormik.values.month2}`}
              isRequired
              errorMessage={editFormik.errors.pumpageMonth2}
              inputState={`${helper.getInputClasses(editFormik, 'pumpageMonth2')}`}
              {...editFormik.getFieldProps('pumpageMonth2')}
            />
          </ContentBlock>

          <ContentBlock cols={12}>
            <InputBlock
              label={`Reported Pumpage: ${editFormik.values.month3}`}
              isRequired
              errorMessage={editFormik.errors.pumpageMonth3}
              inputState={`${helper.getInputClasses(editFormik, 'pumpageMonth3')}`}
              {...editFormik.getFieldProps('pumpageMonth3')}
            />
          </ContentBlock>
        </Section>
      </ModalBlock>
    </CustomBlock>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...alert.actions,
  ...confirmModal.actions,
})(MakePaymentPage);